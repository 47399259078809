import { cleanup } from '@testing-library/react';
import React, { useEffect, useState } from 'react'
import RickAndMortyService from '../../services/RickAndMorty.service';
import { Card } from './Card'

export const Cards = () => {   // en vez de props, podemos poner la variable (mascotas)

    //console.log("props " + JSON.stringify(props));    para ver que llegan todos los datos
    //console.log(mascotas);    usando mascotas en la línea 4

    const[mascotas, setMascotas] = useState([])

    useEffect(() => {

        RickAndMortyService.getAllCharacters()
            .then((data) => setMascotas(data.results))
            .catch((error) => console.log(error));
    }, [mascotas])  //variable que escucha useEffects ante cambios

    const cardsList = mascotas.map((m) => <Card mascota={m} key={m.id}/>)

    return (
        <div className="album py-5 bg-light">
            <div className="container">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                    {cardsList}
                </div>
            </div>
        </div>
    )
}